import { useParams } from "react-router-dom";
import { Breadcrumbs } from "../../../components/breadcrumbs";
import { VerticalTable } from "../../../components/verticalTable";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { fetchAllJobs, fetchCompanies } from "../../../redux/jobsSlice.ts";
import { JobModal } from "./jobModal/index.jsx";

export const BulkUpdate = () => {
	const { id } = useParams();
	const { allJobs, isLoading, companies } = useSelector((store) => store.jobs);
	const [filter, setFilter] = useState({ first: 500 });
	const [show, setShow] = useState(false);
	const [data, setData] = useState({});
	const dispatch = useDispatch();

	useEffect(() => {
		if (filter?.company_id) {
			dispatch(fetchAllJobs({ param: filter }));
		}
	}, [filter]);

	useEffect(() => {
		dispatch(fetchCompanies());
	}, []);

	const tableData = [
		{ value: "title", to: "/jobs/detail/{id}", key: "Title" },
		{
			key: "Company Name",
			value1: "companies",
			nested: true,
			value2: "title",
			value11: "external_companies",
		},
		{
			key: "Employment Type",
			value1: "employment",
			nested: true,
			value2: "name",
		},
		{
			key: "Qualification",
			value1: "education",
			nested: true,
			value2: "education_level",
		},
		{
			key: "Experience",
			value1: "experience",
			nested: true,
			value2: "experience",
		},
		{
			key: "Seniority",
			value1: "seniority",
			nested: true,
			value2: "seniority_name",
		},
		{ key: "City", value: "city" },

		{ key: "Country", value: "country" },
		{ key: "Start Date", value: "start_date" },
		{ key: "Created", value: "created_at" },
	];

	const handleEdit = (e) => {
		console.log(e);
		setData(e);
		setShow(true);
	};

	const actions = [{ type: "edit", func: handleEdit, isLoading: false }];

	return (
		<>
			<JobModal show={show} setShow={setShow} data={data} setData={setData} />
			<Breadcrumbs
				breadcrumbs={[
					{ name: "Jobs", link: "/jobs/view-all" },
					{ name: "Bulk Update", link: "" },
				]}
			/>
			<div className="relative mx-12">
				<div className="flex justify-start items-center space-x-10">
					<div className="flex flex-col w-1/3 gap-y-2">
						<label className="font-light">Select Company</label>
						<select
							value={filter?.company_id || ""}
							onChange={(e) => {
								setFilter({ ...filter, company_id: e.target.value });
							}}
							type="select"
							className="bg-transparent border border-gray-300 text-gray-900 sm:text-sm rounded-lg outline-none focus:border-orange-600 block w-full p-2.5"
						>
							<option value={""}>All</option>
							{(companies || [])
								?.filter((comp) => !comp?.is_original)
								?.map((comp) => {
									return <option value={comp?.id}>{comp?.title}</option>;
								})}
						</select>
					</div>
				</div>
				<div className={`h-[calc(100vh-290px)] overflow-auto mt-5 `}>
					<VerticalTable
						data={tableData}
						table={allJobs}
						actions={actions}
						id={id}
						isLoading={isLoading?.fetchAllJobs}
					/>
				</div>
			</div>
		</>
	);
};
