import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Api from "../services/api";
import { toastNotification } from "../services/notification-service";
interface jobs {
	jobs: any;
	allJobs: any;
	jobInfo: any;
	jobParams: any;
	applicationParams: any;
	categories: any;
	companyCategories: any;
	jobViews: any;
	jobApplications: any;
	jobApplicationData: any;
	companies: any;
	companyDetail: any;
	queries: any;
	isLoading: any;
	errorMsg: any;
}

const initialState: jobs = {
	jobs: {},
	allJobs: {},
	jobInfo: {},
	categories: {},
	companyCategories: {},
	applicationParams: { page: 1 },
	jobParams: {},
	jobViews: {},
	jobApplications: {},
	jobApplicationData: {},
	companies: [],
	companyDetail: {},
	queries: {},
	isLoading: {},
	errorMsg: "",
};

export const jobsSlice = createSlice({
	name: "jobs",
	initialState,
	reducers: {
		setJobs: (state: jobs, { payload }: PayloadAction<any>) => {
			state.jobs = payload;
		},
		setAllJobs: (state: jobs, { payload }: PayloadAction<any>) => {
			state.allJobs = payload;
		},
		setJobInfo: (state: jobs, { payload }: PayloadAction<any>) => {
			state.jobInfo = payload;
		},
		setJobCategories: (state: jobs, { payload }: PayloadAction<any>) => {
			state.categories = payload;
		},
		setCompanyCategories: (state: jobs, { payload }: PayloadAction<any>) => {
			state.companyCategories = payload;
		},
		setCompanyAdd: (state: jobs, { payload }: PayloadAction<any>) => {
			state.categories = { ...state.categories, companies: payload };
		},
		setStatusChange: (state: jobs, { payload }: PayloadAction<any>) => {
			state.jobs = {
				...state.jobs,
				data: state.jobs?.data?.map((job) => {
					return job?.id === payload?.id
						? { ...job, is_published: payload?.value }
						: job;
				}),
			};
		},
		setJobDelete: (state: jobs, { payload }: PayloadAction<any>) => {
			state.jobs = {
				...state.jobs,
				data: state.jobs?.data?.filter((job) => {
					return job?.id !== payload;
				}),
			};
		},
		setJobViews: (state: jobs, { payload }: PayloadAction<any>) => {
			state.jobViews = payload;
		},
		setJobApplications: (state: jobs, { payload }: PayloadAction<any>) => {
			state.jobApplications = payload;
		},
		setJobApplicationData: (state: jobs, { payload }: PayloadAction<any>) => {
			state.jobApplicationData = payload;
		},
		setUpdateAllJobs: (state: jobs, { payload }: PayloadAction<any>) => {
			state.allJobs.data = state.allJobs.data?.map((job) =>
				job?.id === payload?.id ? payload : job
			);
		},
		setJobApplicantsData: (state: jobs, { payload }: PayloadAction<any>) => {
			state.jobApplications = {
				...state.jobApplications,
				data: (state.jobApplications?.data || [])?.map((job) => {
					return job?.id !== payload?.id ? job : payload;
				}),
			};
		},
		setCompanies: (state: jobs, { payload }: PayloadAction<any>) => {
			state.companies = payload;
		},
		setCompaniesAdd: (state: jobs, { payload }: PayloadAction<any>) => {
			const newComp = {
				id: payload?.id,
				slug: payload?.slug,
				title: payload?.title,
			};
			state.companies = [...state.companies, newComp];
		},
		setCompaniesDelete: (state: jobs, { payload }: PayloadAction<any>) => {
			state.companies = state.companies?.filter(
				(company) => Number(company?.id) !== Number(payload)
			);
		},
		setCompanyDetail: (state: jobs, { payload }: PayloadAction<any>) => {
			state.companyDetail = payload;
		},
		setJobParams: (state: jobs, { payload }: PayloadAction<any>) => {
			state.jobParams = payload;
		},
		setQueries: (state: jobs, { payload }: PayloadAction<any>) => {
			state.queries = payload;
		},
		setQueryDelete: (state: jobs, { payload }: PayloadAction<any>) => {
			state.queries = {
				...state.queries,
				data: state.queries?.data?.filter((query) => query?.id !== payload),
			};
		},
		setApplicationParams: (state: jobs, { payload }: PayloadAction<any>) => {
			state.applicationParams = payload;
		},
		setLoading: (state: jobs, { payload }: PayloadAction<any>) => {
			state.isLoading = { ...state.isLoading, ...payload };
		},
		setErrorMsg: (state: jobs, { payload }: PayloadAction<any>) => {
			state.errorMsg = payload;
		},
		reset: (state: jobs) => initialState,
	},
});

export const fetchJobs = (data: any) => async (dispatch: any) => {
	dispatch(setLoading({ fetchJobs: true }));
	const json = await Api.jobs(data);
	if (json?.status === 200) {
		dispatch(setJobs(json?.data?.jobs));
		dispatch(setLoading({ fetchJobs: false }));
	} else {
		dispatch(setLoading({ fetchJobs: false }));
	}
	return json;
};

export const fetchAllJobs = (data: any) => async (dispatch: any) => {
	dispatch(setLoading({ fetchAllJobs: true }));
	const json = await Api.jobs(data);
	dispatch(setLoading({ fetchAllJobs: false }));
	if (json?.status === 200) {
		dispatch(setAllJobs(json?.data?.jobs));
	}
	return json;
};

export const updateAllJobs = (data: any) => async (dispatch: any) => {
	dispatch(setLoading({ update: true }));
	const json = await Api.addJob(data);
	dispatch(setLoading({ update: false }));
	if (json?.status === 200) {
		dispatch(setUpdateAllJobs(json?.data?.data));
		toastNotification({ status: "success", message: "Updated!" });
	}
	return json;
};

export const getJob = (id: any) => async (dispatch: any) => {
	dispatch(setLoading({ getJob: true }));
	const json = await Api.jobInfo(id);
	dispatch(setLoading({ getJob: false }));
	if (json?.status === 200) {
		dispatch(setJobInfo(json?.data?.job));
	}
	return json;
};

export const changeJobStatus = (param: any) => async (dispatch: any) => {
	dispatch(setLoading({ jobStatus: true }));
	const json = await Api.updateJobStatus(param);
	dispatch(setLoading({ jobStatus: false }));
	if (json?.status === 200) {
		dispatch(setStatusChange({ value: param?.value, id: param?.id }));
		toastNotification({ status: "success", message: "Status Changed!" });
	} else {
		toastNotification({ status: "error", message: "Please try again!" });
	}
	return json;
};

export const deleteJob = (id: any) => async (dispatch: any) => {
	dispatch(setLoading({ jobDelete: true }));
	const json = await Api.deleteJob(id);
	dispatch(setLoading({ jobDelete: false }));
	if (json?.status === 200) {
		dispatch(setJobDelete(id));
		toastNotification({
			status: "success",
			message: "Job Deleted Successfully!",
		});
	} else {
		toastNotification({ status: "error", message: "Please try again!" });
	}
	return json;
};

export const fetchJobCategories = () => async (dispatch: any) => {
	const json = await Api.jobCategories();
	if (!!json && json?.status === 200) {
		dispatch(setJobCategories(json?.data));
	}
	return json;
};

export const handleAddCompany = (data: any) => async (dispatch: any) => {
	if (data !== "") {
		dispatch(setLoading({ addCompany: true }));
		const json = await Api.createCompany({ name: data });
		dispatch(setLoading({ addCompany: false }));
		if (json?.status === 201) {
			toastNotification({
				status: "success",
				message: json?.data?.message,
			});
			dispatch(setCompanyAdd(json?.data?.data));
		} else if (json?.response?.status === 422) {
			toastNotification({
				status: "error",
				message: json?.response?.data.errors?.name[0],
			});
		}
	}
};

export const fetchJobViews = (data: any) => async (dispatch: any) => {
	dispatch(setLoading({ jobViews: true }));
	const json = await Api.jobViews(data);
	dispatch(setLoading({ jobViews: false }));
	if (json?.status === 200) {
		dispatch(setJobViews(json?.data?.views));
	}
	return json;
};

export const fetchJobApplications = (data: any) => async (dispatch: any) => {
	dispatch(setLoading({ jobApplication: true }));
	const json = await Api.getApplicants(data);
	dispatch(setLoading({ jobApplication: false }));
	if (json?.status === 200) {
		dispatch(
			setJobApplications(
				data?.id ? json?.data?.job?.applied_candidates : json?.data
			)
		);
	}
	return json;
};

export const fetchJobApplicationData = (data: any) => async (dispatch: any) => {
	dispatch(setLoading({ jobApplicationData: true }));
	const json = await Api.getApplicationData(data);
	dispatch(setLoading({ jobApplicationData: false }));
	if (json?.status === 200) {
		dispatch(setJobApplicationData(json?.data?.applicantDetails));
	}
	return json;
};

export const updateApplicationData = (data: any) => async (dispatch: any) => {
	dispatch(setLoading({ updateApplicationData: true }));
	const json = await Api.updateCandidateApplication(data);
	dispatch(setLoading({ updateApplicationData: false }));
	if (json?.status === 200) {
		toastNotification({
			message: "Application Status Updated!",
			status: "success",
		});
		dispatch(setJobApplicationData(json?.data?.data));
	}
	return json;
};

export const updateApplicantsData = (data: any) => async (dispatch: any) => {
	dispatch(setLoading({ updateApplicantsData: true }));
	const json = await Api.updateCandidateApplication(data);
	dispatch(setLoading({ updateApplicantsData: false }));
	if (json?.status === 200) {
		toastNotification({
			message: "Application Status Updated!",
			status: "success",
		});
		dispatch(setJobApplicantsData(json?.data?.data));
	}
	return json;
};

export const fetchCompanies = () => async (dispatch: any) => {
	dispatch(setLoading({ companies: true }));
	const json = await Api.getCompanies();
	dispatch(setLoading({ companies: false }));
	if (json?.status === 200) {
		dispatch(setCompanies(json?.data?.companies));
		dispatch(setCompanyCategories(json?.data?.company_categories));
	}
	return json;
};

export const fetchCompanyDetail = (id: any) => async (dispatch: any) => {
	dispatch(setLoading({ companyInfo: true }));
	const json = await Api.getCompanyDetail(id);
	dispatch(setLoading({ companyInfo: false }));
	if (json?.status === 200) {
		dispatch(setCompanyDetail(json?.data));
	}
	return json;
};

export const editUpdateCompany = (data, clear, id) => async (dispatch: any) => {
	dispatch(setLoading({ editUpdateCompany: true }));
	const json = await Api.editUpdateCompany(data);
	dispatch(setLoading({ editUpdateCompany: false }));
	if (!!json) {
		if (json?.status === 200) {
			toastNotification({
				message: "Company Edited Successfully!",
				status: "success",
			});
			dispatch(fetchCompanies());
			clear();
		} else if (json?.status === 201) {
			toastNotification({
				message: "Company Created Successfully!",
				status: "success",
			});
			dispatch(fetchCompanies());
			clear();
		} else if (json?.status === 205) {
			toastNotification({
				message: "Company Deleted Successfully!",
				status: "success",
			});
			dispatch(setCompaniesDelete(id));
			clear();
		} else {
			toastNotification({
				message: json?.response?.data?.[0] || "Error!",
				status: "error",
			});
		}
	}
	return json;
};

export const fetchQueries = (param: any) => async (dispatch: any) => {
	dispatch(setLoading({ queries: true }));
	const json = await Api.getQueries(param);
	dispatch(setLoading({ queries: false }));
	if (json?.status === 200) {
		dispatch(setQueries(json?.data));
	}
	return json;
};

export const deleteQuery = (id) => async (dispatch: any) => {
	dispatch(setLoading({ deleteQuery: true }));
	const json = await Api.deleteQuery(id);
	dispatch(setLoading({ deleteQuery: false }));
	if (json?.status === 200) {
		toastNotification({
			message: "Query Deleted!",
			status: "success",
		});
		dispatch(setQueryDelete(id));
	}
	return json;
};

export const {
	setJobs,
	setAllJobs,
	setJobInfo,
	setJobCategories,
	setCompanyCategories,
	setUpdateAllJobs,
	setCompanyAdd,
	setStatusChange,
	setJobDelete,
	setJobViews,
	setJobApplications,
	setApplicationParams,
	setJobApplicationData,
	setJobApplicantsData,
	setCompanies,
	setCompaniesAdd,
	setCompaniesDelete,
	setCompanyDetail,
	setJobParams,
	setQueryDelete,
	setQueries,
	setLoading,
	setErrorMsg,
	reset: resetJobs,
} = jobsSlice.actions;
export default jobsSlice.reducer;
